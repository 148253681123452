import React from 'react';
import * as Styled from './CheckBox.styled';

export default ({ checked, label, handleCheckBox }) => (
  <Styled.BoxWrapper onClick={() => handleCheckBox()}>
    {checked ? (
      <Styled.CheckBoxContainer>
        <Styled.Box>
          <i className="fas fa-check" />
        </Styled.Box>
        <p>{label}</p>
      </Styled.CheckBoxContainer>
    ) : (
      <Styled.CheckBoxContainer>
        <Styled.Box />
        <p>{label}</p>
      </Styled.CheckBoxContainer>
    )}
  </Styled.BoxWrapper>
);
