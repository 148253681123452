import styled from 'styled-components';

// Causes the dates to line break when in mobile view
export default styled.div`
  height: ${({ size }) => size};
  width: 100%;

  @media only screen and (min-width: 475px) {
    display: none;
  }
`;
