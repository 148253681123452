import { createGlobalStyle } from 'styled-components';
import '../styleHelpers/fonts/styles.css';
import { COLORS, media } from 'styleHelpers';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
  }
  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus {
    outline: none;
  }
  .Toastify__toast--success {
    background: ${COLORS.green} !important;
    color: ${COLORS.white};
  }
  .Toastify__toast--error {
    background: ${COLORS.fig} !important;
    color: ${COLORS.white};
  }

  ${media.tablet`
    body {
      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  `}
`;

export { GlobalStyle };
export { default as Wrapper } from './Wrapper/Wrapper';
export { default as CaseBackground } from './CaseBackground/CaseBackground';
export { default as Text } from './Text/Text';
export { default as Container } from './Container/Container';
export { default as Content } from './Content/Content';
export { default as Card } from './Card/Card';
export { default as Image } from './Image/Image';
export { default as Button } from './Button/Button';
export { default as BackButton } from './BackButton/BackButton';
export { default as Input } from './Input/Input';
export { default as TextArea } from './TextArea/TextArea';
export { default as CheckBox } from './CheckBox/CheckBox';
export { default as LineBreak } from './LineBreak/LineBreak';
