import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  BackButton,
  Text,
  Button,
  CheckBox,
  Input,
  TextArea,
  Wrapper,
  CaseBackground
} from 'components';
import { getOrder, selectedOrder, createGuest } from 'helpers';

export default ({ history, match }) => {
  const [selected_case, setSelected_case] = useState(undefined);
  const [theme_image, setTheme_image] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [hideInfo, setHideInfo] = useState(false);
  const [communications, setCommunications] = useState(false);
  const [guest_name, setGuest_name] = useState('');
  const [guest_email, setGuest_email] = useState('');
  const [guest_address, setGuest_address] = useState('');
  const [guest_city, setGuest_city] = useState('');
  const [guest_state, setGuest_state] = useState('');
  const [guest_zip_code, setGuest_zip_code] = useState('');
  const [guest_phone, setGuest_phone] = useState('');
  const [guest_message, setGuest_message] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const resetState = () => {
    setSelected_case(undefined);
    setTheme_image(undefined);
    setOptions(undefined);
    setHideInfo(false);
    setCommunications(false);
    setGuest_name('');
    setGuest_email('');
    setGuest_address('');
    setGuest_city('');
    setGuest_state('');
    setGuest_zip_code('');
    setGuest_phone('');
    setGuest_message('');
    setSubmitting(false);
  };

  const getComponentCase = async () => {
    const selectedOrderExists = selectedOrder.id ? true : false;
    const componentCase = selectedOrderExists
      ? selectedOrder
      : await getOrder(match.params.id);

    setSelected_case(componentCase.case);
    setTheme_image(componentCase.theme_image);
    setOptions(componentCase.options);
  };

  useEffect(() => {
    getComponentCase();
  }, []);

  const goBack = () => history.goBack();

  const submit = async () => {
    setSubmitting(true);
    if (
      (options.name === 'required' && !guest_name) ||
      (options.email === 'required' && !guest_email) ||
      (options.phone === 'required' && !guest_phone) ||
      (options.address === 'required' && !guest_address) ||
      (options.address === 'required' && !guest_city) ||
      (options.address === 'required' && !guest_state) ||
      (options.address === 'required' && !guest_zip_code) ||
      (options.message === 'required' && !guest_message)
    ) {
      setSubmitting(false);
      toast.error('Please complete required fields.');
      return false;
    }

    let payload = {
      case_id: selected_case.id || selectedOrder.case.id,
      name: guest_name,
      email: guest_email.length > 0 ? guest_email : null,
      phone: guest_phone.length > 0 ? guest_phone : null,
      address: guest_address.length > 0 ? guest_address : null,
      city: guest_city.length > 0 ? guest_city : null,
      state: guest_state.length > 0 ? guest_state : null,
      zip_code: guest_zip_code.length > 0 ? guest_zip_code : null,
      message: guest_message.length > 0 ? guest_message : null,
      hide_info: hideInfo || false,
      allow_communication: communications ? false : true
    };

    !payload.email && delete payload.email;
    !payload.phone && delete payload.phone;
    !payload.address && delete payload.address;
    !payload.city && delete payload.city;
    !payload.state && delete payload.state;
    !payload.zip_code && delete payload.zip_code;
    !payload.message && delete payload.message;

    const { id } = await createGuest(payload);
    if (id) {
      setSubmitting(false);
      toast.success('Thank you for signing');
      resetState();
      goBack();
    } else {
      toast.error('Something went wrong.');
    }
  };

  return selected_case && options ? (
    <>
      <CaseBackground theme_image={theme_image} />
      <Container>
        <BackButton onClick={goBack} />
        <Content width="670px" padding="40px">
          <Wrapper list="20px">
            <Text type="title">Sign Registry</Text>
          </Wrapper>
          <Wrapper fullWidth column width="89%" list="15px">
            {options.name !== 'not_visible' && (
              <Input
                is_required={options.name}
                placeholder={`Name ${
                  options.name === 'required' ? ' ' : '(optional)'
                }`}
                onChange={e => setGuest_name(e.target.value)}
                name="guest_name"
                value={guest_name}
              />
            )}
            <Wrapper
              fullWidth
              row={
                options.email !== 'not_visible' &&
                options.phone !== 'not_visible'
                  ? 2
                  : 1
              }
              spacing="15px"
              mobileStretch
            >
              {options.email !== 'not_visible' && (
                <Input
                  is_required={options.email}
                  placeholder={`Email ${
                    options.email === 'required' ? ' ' : '(optional)'
                  }`}
                  name="guest_email"
                  onChange={e => setGuest_email(e.target.value)}
                  value={guest_email}
                />
              )}
              {options.phone !== 'not_visible' && (
                <Input
                  is_required={options.phone}
                  placeholder={`Phone ${
                    options.phone === 'required' ? ' ' : '(optional)'
                  }`}
                  name="guest_phone"
                  onChange={e => setGuest_phone(e.target.value)}
                  value={guest_phone}
                />
              )}
            </Wrapper>

            {options.address !== 'not_visible' && (
              <Input
                is_required={options.address}
                placeholder={`Address ${
                  options.address === 'required' ? ' ' : '(optional)'
                }`}
                name="guest_address"
                onChange={e => setGuest_address(e.target.value)}
                value={guest_address}
              />
            )}
            {options.address !== 'not_visible' && (
              <Wrapper fullWidth row={3} spacing="15px" mobileStretch>
                <Input
                  is_required={options.address}
                  placeholder={`City ${
                    options.address === 'required' ? ' ' : '(optional)'
                  }`}
                  name="guest_city"
                  onChange={e => setGuest_city(e.target.value)}
                  value={guest_city}
                />
                <Input
                  is_required={options.address}
                  placeholder={`State ${
                    options.address === 'required' ? ' ' : '(optional)'
                  }`}
                  name="guest_state"
                  onChange={e => setGuest_state(e.target.value)}
                  value={guest_state}
                />
                <Input
                  is_required={options.address}
                  placeholder={`Zip ${
                    options.address === 'required' ? ' ' : '(optional)'
                  }`}
                  name="guest_zip_code"
                  onChange={e => setGuest_zip_code(e.target.value)}
                  value={guest_zip_code}
                />
              </Wrapper>
            )}
            {options.message !== 'not_visible' && (
              <TextArea
                is_required={options.message}
                placeholder={`Condolence Message ${
                  options.message === 'required' ? ' ' : '(optional)'
                }`}
                maxLength="280"
                name="guest_message"
                onChange={e => setGuest_message(e.target.value)}
                value={guest_message}
              />
            )}

            <Wrapper
              fullWidth
              list="15px"
              paddingBottom="10px"
              left
              mobileStretch
            >
              <CheckBox
                checked={hideInfo}
                handleCheckBox={() => setHideInfo(!hideInfo)}
                label="Hide my name and condolence message from other guests"
              />
              <CheckBox
                checked={communications}
                handleCheckBox={() => setCommunications(!communications)}
                label="I only want to receive communication from the family"
              />
            </Wrapper>
            <Wrapper fullWidth row={2} spacing="15px" mobileStretch>
              <Button outline onClick={goBack}>
                Cancel
              </Button>
              {!submitting && (
                <Button solid onClick={submit}>
                  Submit
                </Button>
              )}

              {submitting && (
                <Button solid style={{ opacity: '.5' }}>
                  Submitting...
                </Button>
              )}
            </Wrapper>
          </Wrapper>
        </Content>
      </Container>
    </>
  ) : (
    <></>
  );
};
