import React, { useState, useEffect } from 'react';
import {
  Container,
  Content,
  Wrapper,
  BackButton,
  Text,
  CaseBackground
} from 'components';
import { selectedOrder, getOrder, getGuests } from 'helpers';

export default ({ history, match }) => {
  const [selected_case, setSelected_case] = useState(undefined);
  const [guests, setGuests] = useState([]);
  const [theme_image, setTheme_image] = useState(undefined);

  const getComponentCase = async () => {
    const selectedOrderExists = selectedOrder.id ? true : false;
    const componentCase = selectedOrderExists
      ? selectedOrder
      : await getOrder(match.params.id);

    setSelected_case(componentCase.case);
    setTheme_image(componentCase.theme_image);
    setGuests(await getGuests(componentCase.case.id));
  };

  useEffect(() => {
    getComponentCase();
  }, []);

  const goBack = () => history.goBack();

  return selected_case ? (
    <>
      <CaseBackground theme_image={theme_image} />
      <Container>
        <BackButton onClick={goBack} />
        <Content width="670px" padding="54px">
          <Wrapper fullWidth>
            <Wrapper fullWidth spacing="20px" marginBottom="58px" middle>
              <Text type="title">Guests</Text>
            </Wrapper>
            <Wrapper fullWidth list="48px">
              {guests &&
                guests.map(guest => (
                  <Wrapper fullWidth key={guest.id} column left list="8px">
                    <Text type="guest">{guest.name}</Text>
                    {guest.message && (
                      <Text type="guestMessage">{guest.message}</Text>
                    )}
                  </Wrapper>
                ))}
            </Wrapper>
          </Wrapper>
        </Content>
      </Container>
    </>
  ) : (
    <></>
  );
};
