import styled from 'styled-components';
import { COLORS } from 'styleHelpers';
const { defaultNavy, white, gray, black } = COLORS;

export const BoxWrapper = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border: ${gray} 1.5px solid;
  background: ${white};
  border-radius: 5px;
  margin-right: 15px;

  & i {
    top: 1px;
    font-size: 1em;
    position: relative;
  }
`;

export const CheckBoxContainer = styled.div`
  color: ${defaultNavy};
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  user-select: none;
  width: 100%;
  margin: ${({ margin = '0' }) => margin};

  & p {
    color: ${black};
    font-size: 1.125em;
    display: flex;
    align-items: center;
  }

  &:active ${Box} {
    color: ${white};
    background: ${defaultNavy};
    border: ${defaultNavy} 1.5px solid;
  }
`;
