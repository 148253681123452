import styled from 'styled-components';
import { COLORS } from 'styleHelpers';

const { white, gray, textGrey } = COLORS;

export default styled.input`
  height: 50px;
  width: ${({ width }) => (width ? width : '100%')};
  border: ${({ is_required }) =>
      is_required === 'required' ? `#194c7399` : `${gray}`}
    solid 1.5px;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 1.125em;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  background: ${white};
  &::placeholder {
    color: ${textGrey};
    font-weight: normal;
  }
`;
