import { http } from 'helpers';
import moment from 'moment';

let orders = [];
let selectedOrder = {};

const getOrder = async id => {
  const order = await http().get(`/orderForService?id=${id}`);
  if (!order.case.personal_images) {
    order.case.personal_images = [];
    order.case.personal_images.push('Grey_Dove.png');
  }
  selectedOrder = order;
  return order;
};

const getOrders = async slug => {
  selectedOrder = {};
  try {
    const res = await http().get(`/ordersForService?slug=${slug}`);

    const resp_orders = res.filter(order => {
      let today = new Date();
      if (
        !order.options.service_start_date ||
        !order.options.service_end_date ||
        (moment(order.options.service_start_date).isSameOrBefore(
          today,
          'day'
        ) &&
          moment(order.options.service_end_date).isSameOrAfter(today, 'day'))
      ) {
        if (!order.case.personal_images) {
          order.case.personal_images = [];
          order.case.personal_images.push('Grey_Dove.png');
        }
        return order;
      } else {
        return false;
      }
    });
    return resp_orders;
  } catch (e) {
    throw e;
  }
};

const selectOrder = async case_object => {
  selectedOrder = await getOrder(case_object.id);
};

export { orders, selectedOrder, getOrder, getOrders, selectOrder };
