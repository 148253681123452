import styled from 'styled-components';
import { COLORS } from 'styleHelpers';
const { defaultNavy, white } = COLORS;

export default styled.button`
  font-size: 1.25em;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 13px 15px;
  color: ${defaultNavy};
  background-color: ${white};
  border: none;
  position: fixed;
  top: 30px;
  left: 30px;
  height: 56px;
  width: 56px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

  &:after {
    content: '←';
  }
  &:active {
    opacity: 0.8;
  }
`;
