import styled from 'styled-components';
import { media, COLORS } from 'styleHelpers';
import { Image } from '..';

const { transparentWhite } = COLORS;

export default styled.div`
  width: ${({ width }) => width && width};
  padding: ${({ padding }) => padding && padding};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${transparentWhite};
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
  transition: .25s;

  ${media.tablet`
    width: 90vw;
  `}

  ${media.mobileL`
    width: 90vw;
    background: rgba(255, 255, 255, .80);
    & ${Image} {
      min-width: 250px;
      min-height: 250px;
    }
    padding: ${({ padding }) =>
      padding && `${Math.floor(parseInt(padding) / 1.5)}px`};
  `}

  ${media.mobileS`
    & ${Image} {
      min-width: 200px;
      min-height: 200px;
    }
  `}
`;
