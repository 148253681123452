import axios from 'axios';
import { toast } from 'react-toastify';

export default (toastifyErrors = true) => {
  console.log(process.env.REACT_APP_API_URL);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      // 'x-auth-token': window.localStorage.getItem('token'),
      // 'x-auth-token': process.env.REACT_APP_AUTH_TOKEN
    }
  });

  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      if (toastifyErrors) {
        if (error.response && error.response.data) {
          if (error.response.data.validation) {
            toast.error(error.response.data.validation[0].message);
          } else if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Server Error!');
          }
        } else {
          toast.error('Server Error!');
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
