import styled from 'styled-components';
import { COLORS } from 'styleHelpers';

const { gray, textGrey } = COLORS;

export default styled.textarea`
  height: 110px;
  width: 100%;
  border: ${({ is_required }) =>
      is_required === 'required' ? `#194c7399` : `${gray}`}
    solid 1.5px;
  border-radius: 5px;
  padding: 17px 21px;
  resize: none;
  font-size: 1.125em;
  &::placeholder {
    color: ${textGrey};
  }
`;
