import React, { useState, useEffect } from 'react';
import {
  Container,
  Content,
  Wrapper,
  Text,
  Image,
  Button,
  BackButton,
  CaseBackground
} from 'components';
import { getOrder, formatDate, selectedOrder } from 'helpers';

export default ({ history, match }) => {
  const [selected_case, setSelected_case] = useState(undefined);
  const [theme_image, setTheme_image] = useState(undefined);

  const getComponentCase = async () => {
    const selectedOrderExists = selectedOrder.id ? true : false;
    const componentCase = selectedOrderExists
      ? selectedOrder
      : await getOrder(match.params.id);

    setSelected_case(componentCase.case);
    setTheme_image(componentCase.theme_image);
  };

  useEffect(() => {
    getComponentCase();
  }, []);

  const goBack = () => history.goBack();

  const navigate = e => {
    const url = match.path;
    const new_url = url.substring(0, url.indexOf('/:'));

    history.push(`${new_url}/${e.target.name}/${selectedOrder.id}`);
  };

  return selected_case ? (
    <>
      <CaseBackground theme_image={theme_image} />
      <Container>
        <BackButton onClick={goBack} />
        <Content width="670px">
          <Wrapper width="80%">
            <Wrapper margin="50px 0 40px 0">
              {selected_case && (
                <Image image={selected_case.personal_images[0]} shadow border />
              )}
            </Wrapper>
            <Wrapper marginBottom="30px">
              {selected_case && (
                <Text type="largeName">{selected_case.name_of_deceased}</Text>
              )}
              {selected_case && (
                <Text type="dates">
                  {formatDate(selected_case.date_of_birth, 'LL')}
                  <br />

                  {formatDate(selected_case.date_of_death, 'LL')}
                </Text>
              )}
            </Wrapper>
            <Wrapper row={2} spacing="15px" mobileStretch marginBottom="48px">
              <Button outline name="guests" onClick={navigate}>
                {' '}
                View Guests{' '}
              </Button>
              <Button solid name="sign" onClick={navigate}>
                {' '}
                Sign Registry{' '}
              </Button>
            </Wrapper>
          </Wrapper>
        </Content>
      </Container>
    </>
  ) : (
    <></>
  );
};
