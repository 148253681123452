import styled from 'styled-components';

export const Background = styled.div`
  background: ${({ themeImage }) =>
    themeImage
      ? `url('https://bassmollett-theme.imgix.net/${themeImage}') no-repeat 50% 50% / cover`
      : `url('https://bassmollett-theme.imgix.net/tulips.jpg') no-repeat 0% 50% / cover`};
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`;
