import styled from 'styled-components';
import { COLORS, media } from 'styleHelpers';

const { slate, black } = COLORS;

export default styled.span`
  text-align: ${({ align }) => (align ? align : 'left')};
  ${({ type }) => {
    if (type === 'name') {
      return `
        font-size: 3.125em;
        font-family: Book Antiqua Italic;
        color: ${black};
        text-align: center;
        {
          ${media.mobileL}
            font-size: 3.2em;
        }
      `;
    } else if (type === 'largeName') {
      return `
        font-size: 3.75em;
        font-family: Book Antiqua Italic;
        color: ${black};
        text-align: center;
        {
        ${media.mobileL}
          font-size: 3.2em;
        }
      `;
    } else if (type === 'title') {
      return `
        font-size: 3em;
        letter-spacing: 2px;
        font-family: Book Antiqua Italic;
        text-align: center;
      `;
    } else if (type === 'guest') {
      return `
        font-size: 1.5em;
        font-weight: bold;
        font-family: Book Antiqua Italic;
        color: ${slate};
      `;
    } else if (type === 'guestMessage') {
      return `
          font-size: 1.125em;
          word-break: break-word;
        `;
    } else if (type === 'landing') {
      return `
        font-size: 2.75em;
        font-family: Book Antiqua Italic;
        color: ${black};
        text-align: center;
      `;
    } else if (type === 'dates') {
      return `
        font-size: 1.875em;
        text-align: center;
      `;
    } else if (type === 'cardDates') {
      return `
        font-size: 1.5em;
        text-align: center;
      `;
    }
  }};
`;
