import React, { useState, useEffect } from 'react';
import { getOrders, formatDate, selectOrder } from 'helpers';
import { Container, Wrapper, Text, Card, Image } from 'components';
import { CaseBackground } from 'components';

export default ({ history, match }) => {
  const [orders, setOrders] = useState(undefined);

  const getComponentOrders = async () => {
    try {
      const orders = await getOrders(match.params.slug);
      setOrders(orders);
    } catch (e) {
      history.push('/');
    }
  };

  useEffect(() => {
    getComponentOrders();
  }, []);
  const { slug } = match.params;

  return orders ? (
    <>
      <CaseBackground />
      <Container>
        <Wrapper list="30px">
          {orders &&
            orders.map(item => (
              <Card
                full
                padding="46px"
                key={item.id}
                onClick={() => {
                  selectOrder(item);
                  history.push(`${slug}/service/${item.id}`);
                }}
              >
                <Wrapper fullWidth row>
                  <Image large border image={item.case.personal_images[0]} />
                  <Wrapper fullWidth left spacing="5px">
                    <Text type="name">{item.case.name_of_deceased}</Text>
                    <Text type="cardDates">
                      {formatDate(item.case.date_of_birth, 'LL')} {` \u2014 `}
                      {formatDate(item.case.date_of_death, 'LL')}
                    </Text>
                  </Wrapper>
                </Wrapper>
              </Card>
            ))}
        </Wrapper>
      </Container>
    </>
  ) : (
    <></>
  );
};
