import { COLORS, media } from 'styleHelpers';
import styled from 'styled-components';
import { Image, Text } from '..';

const { transparentWhite } = COLORS;

export default styled.div`
  width: 84vw;
  min-height: 295px;
  background-color: ${transparentWhite};
  cursor: default;
  user-select: none;
  padding: ${({ padding }) => (padding ? padding : '46px')};
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);

  & ${Image} {
    margin-right: 46px;
  }

  ${media.tablet`
    & > * {
      flex-direction: column;
    }
    & ${Image} {
      margin-right: 0;
    }
    & ${Text} {
      text-align: center !important;
      width: 100%
    }
  `}
`;
