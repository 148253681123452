import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';

import { GlobalStyle } from 'components';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js/fn/string/includes';
import * as serviceWorker from 'serviceWorker';
serviceWorker.unregister();

const App = () => (
  <>
    <GlobalStyle />
    <Router />
    <ToastContainer position="top-right" autoClose={2000} transition={Slide} />
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));
