import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Case from './Case';
import Guests from './Guests';
import Sign from './Sign';

export default props => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`${props.match.url}/:id`} component={Case} />
        <Route path={`${props.match.url}/guests/:id`} component={Guests} />
        <Route path={`${props.match.url}/sign/:id`} component={Sign} />
      </Switch>
    </BrowserRouter>
  );
};
