import React, { useState, useEffect } from 'react';
import { selectedOrder } from 'helpers';
import * as Styled from './CaseBackground.styled';

export default props => {
  const [themeImage, setThemeImage] = useState(null);

  const getComponentTheme = async () => {
    selectedOrder.theme_image && setThemeImage(selectedOrder.theme_image);
  };

  useEffect(() => {
    getComponentTheme();
  }, []);

  return <Styled.Background themeImage={themeImage} {...props} />;
};
