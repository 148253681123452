import React, { useState } from 'react';
import {
  Container,
  Wrapper,
  Text,
  Card,
  CaseBackground,
  LineBreak,
  Input,
  Button
} from 'components';

export default ({ history }) => {
  const [home, setHome] = useState('');
  const changeSlug = e => setHome(e.target.value);
  const nav = () => history.push(`/${home}`);

  return (
    <>
      <CaseBackground />
      <Container>
        <Card padding="30px" full>
          <Wrapper row>
            <Wrapper center fullWidth spacing="7px">
              <Text align="center" type="landing">
                Please enter the complete web address <br /> for the service you
                are attending, <br /> or enter the funeral home identifier
                below:
              </Text>
              <LineBreak style={{ display: 'inherit' }} size="20px" />

              {/* Chrome respects autoComplete='off' only when there is at least one other input on the page with an autocomplete value *eye-roll*  */}
              <div style={{ display: 'none' }}>
                <input
                  type="text"
                  id="PreventChromeAutocomplete"
                  name="PreventChromeAutocomplete"
                  autoComplete="address-level4"
                />
              </div>
              <Input
                style={{ width: '50%' }}
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="off"
                spellcheck="off"
                name="slug"
                value={home}
                onChange={changeSlug}
              />
              <LineBreak size="10px" style={{ display: 'inherit' }} />
              <Button width="75px" onClick={nav}>
                Go
              </Button>
              <LineBreak size="20px" style={{ display: 'inherit' }} />
              <Text align="center" type="cardDates">
                If you are unsure what the web address is, ask any staff at the
                service for assistance.
              </Text>
            </Wrapper>
          </Wrapper>
        </Card>
      </Container>
    </>
  );
};
