import styled from 'styled-components';
import { media } from 'styleHelpers';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px;

  ${media.mobileL`
    padding: 3vh 0;
  `}
`;
