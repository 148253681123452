import { css } from 'styled-components';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  phablet: '550px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export default Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${size[label]}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
