export default {
  defaultNavy: '#194c73',
  defaultGold: '#d9ba6e',
  gray: '#ddd',
  slate: '#868D92',
  lightCream: '#f7f1e0',
  white: '#fff',
  goldHover: '#154265', //?
  lightGray: '#f8f8f8',
  memorialBox: '#ece9e95c',
  black: '#000',
  transparentWhite: 'rgba(255, 255, 255, .95)',
  green: '#9dba8c',
  fig: '#9d6a8c',
  textGrey: '#9fa5a9'
};

// lr-outlineGray = #f0f0f0
// clr-orange = #F26531
// light-gray = #f8f8f8
// dark-gray = #bdbec0
// gold = #a68438
// navy = #758096
// modal-gray = #c8ccd5
// border-gray = #e4e5e5

// primary = #d9ba6e
// primary2 = #194c73
// primary2-hover = #154265

// gray = #ddd
// salmon = #d97d6e
// orange = #d99b6e
// yellow = #e8e186
// lime = #bbd86e
// green = #9dba8c
// grayblue = #7f9caa
// purple = #7f7eaa
// fig = #9d6a8c
// pink = #d77cb4
