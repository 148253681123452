import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from 'routes/Main';
import NotFound from 'routes/NotFound';
import Service from 'routes/Service';

const AuthenticatedRoute = props => {
  if (props.secure) {
    //const authToken = window.localStorage.getItem('token');
    // if (!authToken) {
    //   return <Redirect to="/" />;
    // }
    return <Route {...props} />;
  } else {
    // if (window.localStorage.getItem('token')) {
    //   return <Redirect to="/" />;
    // }
    return <Route {...props} />;
  }
};

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute exact path="/" component={NotFound} />
        <AuthenticatedRoute exact path="/:slug" component={Main} />
        <AuthenticatedRoute path="/:slug/service" component={Service} />
      </Switch>
    </BrowserRouter>
  );
};
