import styled from 'styled-components';

export default styled.div`
  min-width: ${({ large }) => (large ? '194px' : '320px')};
  min-height: ${({ large }) => (large ? '194px' : '320px')};
  box-sizing: content-box;
  background-position: center center;
  background-size: cover;
  box-shadow: ${({ shadow }) =>
    shadow ? '0px 6px 24px 0px rgba(0,0,0,0.16);' : 'none'};
  border: ${({ border }) => (border ? '5px solid white' : 'none')};
  background-image: url('https://bassmollett-case.imgix.net/${({ image }) =>
    image}?fit=facearea&facepad=3.5&w=200&h=200');
`;
