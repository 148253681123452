import styled from 'styled-components';
import { media } from 'styleHelpers';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100%')};

  margin: ${({ margin }) => margin && margin};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};

  padding: ${({ padding }) => padding && padding};
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
  
  /* horizontal and vertical alignment */
  ${({ top, bottom, middle, left, right, center }) => {
    if (top) return `justify-content: flex-start;`;
    if (bottom) return `justify-content: flex-end;`;
    if (middle) return `justify-content: center;`;
    if (left) return `align-items: flex-start;`;
    if (right) return `align-items: flex-end;`;
    if (center) return `align-items: center;`;
  }}

  /* flex direction */
  ${({ column, row, spacing }) => {
    if (column) return `flex-direction: column;`;
    if (row)
      return `
      flex-direction: row;
      ${row > 1 && `justify-content: space-between !important;`}
      & > * {
        width: calc((100% / ${row > 0 ? row : '0'}) - ${
        spacing ? spacing : '0'
      }) !important;
      }
    `;
  }}

${({ list }) => {
  if (list)
    return `
      flex-direction: column;
      & > * {
        margin-bottom: ${list ? list : '0'} !important;
      }
      & > *:last-child {
        margin-bottom: 0 !important;
      }
      & > *:only-child {
        margin-bottom: ${list ? list : '0'} !important;
      }
    `;
}}
  
  /* when viewed on mobile, make elements 100% and in a column */
  ${media.mobileL`
    flex-direction: column;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '89%')};
    & > * {
      width: ${({ mobileStretch }) => mobileStretch && '100% !important'};
      margin-bottom: ${({ row, spacing }) => row && spacing};
    }
    & > *:last-child {
      margin-bottom: ${({ row }) => row && 0};
    }
  `}

`;
