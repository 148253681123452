import styled from 'styled-components';
import { COLORS } from 'styleHelpers';

const { defaultNavy, white } = COLORS;

export default styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  height: 60px;
  font-size: 1.25em;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.15s;

  ${({ outline, solid }) => {
    if (outline) {
      return `
        color: ${defaultNavy};
        background: transparent;
        border: 1.5px solid ${defaultNavy};
        &:hover {
          color: ${white};
          background: ${defaultNavy};
        }
      `;
    } else if (solid) {
      return `
        color: ${white};
        background: ${defaultNavy};
        border: 1.5px solid ${defaultNavy};
      `;
    } else {
      return `
        color: ${defaultNavy};
        background: transparent;
        border: 1.5px solid ${defaultNavy};
      `;
    }
  }}

  &:active {
    opacity: 0.8;
  }
`;
